<template>
<div>
    <div class="h-handle-bg">
        <div class="h-handle-button">
            <p class="h-return">
                <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
            </p>
            <div class="h-b">
                <el-button type="primary" size="small" @click="add" v-right-code="'SysStaff:Edit'">新增</el-button>
            </div>
            <div class="h-b">
                <el-button type="text" size="small" @click="save" v-loading="loadding" v-right-code="'SysStaff:Edit'">保存</el-button>
            </div>
        </div>
    </div>
    <div class="form-list">
        <el-form ref="_staffInfoForm" :model="dataSource" :rules="staffCheckRule">
            <el-form-item>
                <el-col :span="3" class="form-title"><span style="color:red;">*</span>姓名：</el-col>
                <el-col :span="7">
                    <el-form-item prop="Name">
                        <el-input v-model.trim="dataSource.Name" :maxlength="30" :minlength="4" :disabled="isDisabled" :readonly="isDisabled" placeholder="姓名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title"><span style="color:red;">*</span>员工手机号：</el-col>
                <el-col :span="7">
                    <el-form-item prop="Phone">
                        <el-input v-model.trim="dataSource.Phone" :maxlength="50" placeholder="员工手机号" autofocus="autofocus"></el-input>
                    </el-form-item>
                </el-col>
            </el-form-item>
            <el-form-item v-if="!isDisabled">
                <el-col :span="3" class="form-title">性别：</el-col>
                <el-col :span="7">
                    <el-form-item prop="Sex">
                        <DictionarySelect v-model="dataSource.Sex" datType='Int' dicTypeCode="SexTypeCode" @change="sexChange"></DictionarySelect>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title"><span style="color:red;">*</span>岗位：</el-col>
                <el-col :span="7">
                    <el-form-item prop="Post">
                        <DictionarySelect ref="postRef" v-model="dataSource.Post" dicTypeCode="StaffPostTypeCode" @change="postChange"></DictionarySelect>
                    </el-form-item>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="3" class="form-title"><span style="color:red;">*</span>员工类型：</el-col>
                <el-col :span="7">
                    <el-form-item prop="Type">
                        <DictionarySelect ref="typeRef" v-model="dataSource.Type" dicTypeCode="StaffTypeCode" @change="typeChange"></DictionarySelect>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title"><span style="color:red;">*</span>在职状态：</el-col>
                <el-col :span="7">
                    <el-form-item prop="DutyStatus">
                        <DictionarySelect ref="dutyStatusRef" v-model="dataSource.DutyStatus" dicTypeCode="DutyStatusCode" @change="dutyStatusChange"></DictionarySelect>
                    </el-form-item>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="3" class="form-title">工号：</el-col>
                <el-col :span="7">
                    <el-form-item prop="JobNumber">
                        <el-input v-model.trim="dataSource.JobNumber" :maxlength="50" :disabled="isDisabled" :readonly="isDisabled" placeholder="工号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title">邮箱：</el-col>
                <el-col :span="7">
                    <el-form-item prop="Email">
                        <el-input v-model.trim="dataSource.Email" :maxlength="50" placeholder="邮箱"></el-input>
                    </el-form-item>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="3" class="form-title">状态：</el-col>
                <el-col :span="7">
                    <el-switch v-model="dataSource.Status" :active-value="100" :inactive-value="200" active-color="#13ce66" inactive-color="gray"></el-switch>
                </el-col>
            </el-form-item>
        </el-form>
    </div>
    <el-tabs type="border-card" v-model="tabActiveName">
        <el-tab-pane label="关联组织" name="tabRole">
            <div class="h-handle-button">
                <p>
                    <el-button type="text" size="small" @click="addRelation">关联组织</el-button>
                </p>
            </div>
            <div class="form-padding">
                <el-table v-loading="loadding" ref="_relationList" :data="dataSource.StaffRelationList" style="width: 100%" border highlight-current-row>
                    <el-table-column type="index" label="序号" width="55">
                    </el-table-column>
                    <el-table-column prop="DisplayType" label="类型"></el-table-column>
                    <el-table-column prop="TypeName" label="名称"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" style="text-decoration:underline;color:#1874CD;cursor:pointer" @click="remove(scope.row)">移除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-tab-pane>
    </el-tabs>
    <DialogEx :options="relationOptions" title="关联组织" style="height:100%;" @onOk="onOk">
        <Relation ref="relation" :currentSelectedRows="currentSelectedRows">
        </Relation>
    </DialogEx>
</div>
</template>

<script>
export default {
    data() {
        var checkName = function (rule, value, callback) {
            if (!value) return callback(new Error('姓名不能为空！'));
            callback();
        };
        var checkPhone = function (rule, value, callback) {
            if (!value) return callback(new Error('员工手机号不能为空！'));
            var phoneReg = /^1[3-578]\d{9}$/;
            if (!phoneReg.test(value)) {
                return callback(new Error('手机号格式不正确！'));
            }
            callback();
        };
        var checkEmail = function (rule, value, callback) {
            var mailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if (value && !mailReg.test(value)) {
                return callback(new Error('邮箱格式不正确！'));
            }
            callback();
        };
        var checkPost = function (rule, value, callback) {
            if (!value) return callback(new Error('员工岗位不能为空！'));
            callback();
        };
        var checkType = function (rule, value, callback) {
            if (!value) return callback(new Error('员工类型不能为空！'));
            callback();
        };
        var checkDutyStatus = function (rule, value, callback) {
            if (!value) return callback(new Error('在职状态不能为空！'));
            callback();
        };
        return {
            multipleSelection: [],
            dataSourceCopy: {},
            loadding: false,
            isDisabled: false,
            tabActiveName: 'tabRole',
            currentEnterpriseSelectedRows: [],
            relationOptions: {
                visible: false,
                size: 'large'
            },

            staffCheckRule: {
                Name: [{
                    validator: checkName,
                    trigger: 'blur'
                }],
                Phone: [{
                    validator: checkPhone,
                    trigger: 'blur'
                }],
                Email: [{
                    validator: checkEmail,
                    trigger: 'blur'
                }],
                Post: [{
                    validator: checkPost,
                    trigger: 'blur'
                }],
                Type: [{
                    validator: checkType,
                    trigger: 'blur'
                }],
                DutyStatus: [{
                    validator: checkDutyStatus,
                    trigger: 'blur'
                }],
            },
            currentSelectedRows: {
                customerRows: [],
                organizationRows: [],
                carrierRows: [],
                customervendorRows: [],
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    mounted() {
        this.Event.$on("clearEditUserForm", () => this.resetForm);
    },
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                this.isDisabled = this.dataSource.UserId && this.dataSource.UserId != 0;
                if (curVal.IsSuperAdmin) {}
            },
            deep: true
        },
    },
    methods: {
        tab2Click: function (tab, event) {},
        handleSelectionChange(val) {

            if (val.length > 0)
                val.forEach((item, index) => item.Checked = true);
            else
                this.dataSource.Roles.forEach((item, index) => item.Checked = false);
        },
        add() {
            this.Event.$emit("onAdd");
        },
        resetForm() {
            !this.isPassValidate && this.$refs['_staffInfoForm'].resetFields(); //清空表单
        },
        //保存
        save() {
            var _this = this;
            _this.$refs["_staffInfoForm"].validate((valid) => {
                _this.isPassValidate = valid;
                if (valid) {
                    var routeName = _this.dataSource.Id ===null ? "create" : "edit";
                    _this.$ajax.send("omsapi/sysstaff/"+routeName, "post", _this.dataSource, (data) => {
                        _this.dataSource.Id === _this.Utils.emptyGuid && (_this.dataSource.Id =
                            data.Result);
                        _this.$parent.syncDataSource();
                        _this.Event.$emit("reloadStaffPageList", data.Result);
                        _this.Utils.messageBox("保存成功.", "success");
                    });
                } else {
                    return false;
                }
            });
        },
        sexChange(val) {
            this.dataSource.Sex = val;
        },
        postChange(val) {
            this.dataSource.Post = val;
        },
        typeChange(val) {
            this.dataSource.Type = val;
        },
        dutyStatusChange(val) {
            this.dataSource.DutyStatus = val;
        },
        addRelation() {
			this.currentSelectedRows.customerRows=[];
			this.currentSelectedRows.organizationRows=[];
			this.currentSelectedRows.carrierRows=[];
			this.currentSelectedRows.customervendorRows=[];
            this.relationOptions.visible = true;
        },
        onOk() {
			this.dataSource.StaffRelationList=this.dataSource.StaffRelationList||[];
			if(this.currentSelectedRows.customerRows.length>0){
				this.currentSelectedRows.customerRows.forEach(item=>{
					var newRow = {Id:null,TypeId: item.CustomerID, Type: 20, DisplayType: '货主',TypeName: item.CustomerName};
					if(this.dataSource.StaffRelationList.filter(m=>m.Type==newRow.Type&&m.TypeId==newRow.TypeId).length<=0){
						this.dataSource.StaffRelationList.push(newRow);
					}					
				});				
			}
			if(this.currentSelectedRows.organizationRows.length>0){
				this.currentSelectedRows.organizationRows.forEach(item=>{
					var newRow = {Id:null,TypeId: item.Id, Type: 10, DisplayType: '组织',TypeName: item.Name};
					if(this.dataSource.StaffRelationList.filter(m=>m.Type==newRow.Type&&m.TypeId==newRow.TypeId).length<=0){
						this.dataSource.StaffRelationList.push(newRow);
					}	
				});
			}
			if(this.currentSelectedRows.customervendorRows.length>0){
				this.currentSelectedRows.customervendorRows.forEach(item=>{
					var newRow = {Id:null,TypeId: item.VendorId, Type: 30, DisplayType: '客商',TypeName: item.VendorName};
					if(this.dataSource.StaffRelationList.filter(m=>m.Type==newRow.Type&&m.TypeId==newRow.TypeId).length<=0){
						this.dataSource.StaffRelationList.push(newRow);
					}	
				});	
			}
			if(this.currentSelectedRows.carrierRows.length>0){
				this.currentSelectedRows.carrierRows.forEach(item=>{
					var newRow = {Id:null,TypeId: item.MemberId, Type: 40, DisplayType: '承运商',TypeName: item.MemberName};
					if(this.dataSource.StaffRelationList.filter(m=>m.Type==newRow.Type&&m.TypeId==newRow.TypeId).length<=0){
						this.dataSource.StaffRelationList.push(newRow);
					}	
				});	
			}
			this.relationOptions.visible = false;
        },
        remove(row) {
			var _this = this;
                if (_this.dataSource.Id !== null && row.Id != null) {
                    _this.$ajax.send("omsapi/sysstaff/removestaffrelation", "post", row, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox("移除成功.", "success");
                            this.dataSource.StaffRelationList.remove(row);
                        }
                        else {
                            _this.Utils.messageBox("移除失败，清重试.", "warning");
                        }
                    });
                }
                else {
                    this.dataSource.StaffRelationList.remove(row);
                }
        },

    },
    components: {
        "Relation": resolve => {
            require(['./relation.vue'], resolve)
        },
    }
}
</script>

<style></style>
